const tradeTraiffPanel = '/trade-and-traiff-panel'
export const tradeTariffDashboard = tradeTraiffPanel + '/dashboard'
export const externalApplicationList = tradeTraiffPanel + '/external-application/list'
export const externalApplicationStore = tradeTraiffPanel + '/external-application/store'
export const searchHsCode = tradeTraiffPanel + '/search-hs-code'
export const externalCountryTariffList = tradeTraiffPanel + '/country-tariff/list'
export const consultantProfileList = tradeTraiffPanel + '/request-for-appointment/list'
export const requestForConsultationProcessing = tradeTraiffPanel + '/request-for-appointment/update'
export const appointmentHistoryList = tradeTraiffPanel + '/appointment-history/list'
export const externalFeedbackStore = tradeTraiffPanel + '/appointment-history-feedback/store'
const countryInformationManagement = '/world-trade-directory-management/country-information-management/'
export const countryInformationManagementList = countryInformationManagement + 'list'
export const countryInformationManagementStore = countryInformationManagement + 'store'
export const countryInformationManagementUpdate = countryInformationManagement + 'forward'
export const countryInformationManagementToggle = countryInformationManagement + 'toggle-status'
export const tariffPortsLstApi = countryInformationManagement + 'ports'

// country profile related route
const countryProfile = tradeTraiffPanel + '/country-profile/'
export const countryProfilePorts = countryProfile + 'ports'
export const countryProfileList = countryProfile + 'list'
